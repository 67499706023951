import { FC } from 'react'
import { Box } from '../custombox/CustomBox'
import { styles } from './ProductListPage.style'
import Layout from '../layout/Layout'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { useProducts } from '../../api/hooks/useProductsQuery'
import { useNavigate } from 'react-router'
import { paths } from '../../paths'

export interface ProductListPageProps {
  logout: () => void
}
/**
 * ProductListPage provides a list of products to navigate to.
 * @param props Properties for the component.
 * @returns React component.
 */
export const ProductListPage: FC<ProductListPageProps> = ({ logout }: ProductListPageProps) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { data: products, isLoading } = useProducts()

  const tableBody = (): JSX.Element => {
    if (products === undefined || Object.values(products).length === 0) {
      return (
        <TableBody sx={styles.table}>
          <TableRow>
            <TableCell>
              {isLoading
                ? formatMessage({ id: 'productList.loading' })
                : formatMessage({ id: 'productList.noProducts' })}
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
    return (
      <TableBody sx={styles.table}>
        {Object.values(products).map(product => (
          <TableRow
            key={product.uuid}
            hover
            sx={styles.tableBodyRow}
            onClick={() =>
              navigate(`${paths.products}/${product.uuid}`)}
          >
            <TableCell component='th' scope='row' sx={styles.titleCellBody}>
              {product.title}
            </TableCell>
            <TableCell align='left' sx={styles.descriptionCellBody}>
              {product.status}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  return (
    <Layout logout={logout}>
      <Box sx={styles.content}>
        <Typography variant='h4' sx={styles.headerText}>
          <FormattedMessage id='productList.products' />
        </Typography>
        <TableContainer sx={styles.tableContainer} component={Paper}>
          <Table>
            <TableHead sx={styles.table}>
              <TableRow sx={styles.tableRow}>
                <TableCell align='left' sx={styles.titleCell}>
                  <FormattedMessage id='productList.title' />
                </TableCell>
                <TableCell align='left' sx={styles.descriptionCell}>
                  <FormattedMessage id='productList.status' />
                </TableCell>
              </TableRow>
            </TableHead>
            {tableBody()}
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  )
}

export default ProductListPage
