import { Text, View } from '@react-pdf/renderer'
import { styles } from './InfoBoxView.style'

export interface InfoBoxViewProps {
  rows: Array<[string?, string?]>
}

/**
 * Displays a small info box in bottom right corner with a set of key-value pairs
 */
export const InfoBoxView = ({ rows }: InfoBoxViewProps): JSX.Element => {
  return (
    <View style={styles.infoBox}>
      {rows.map(([name, value], index) => {
        return (
          <View key={index} style={styles.row}>
            <Text style={styles.rowName}>
              {name}
            </Text>
            <Text>
              {name !== undefined ? ':' : ' '} {value}
            </Text>
          </View>
        )
      })}
    </View>
  )
}

export default InfoBoxView
