import { createStyles, theme } from '../../theme'

export const styles = createStyles({
  content: {
    padding: 2
  },
  container: {
    height: '90vh'
  },
  materialsAndSizeContainer: {
    width: '50%',
    overflowY: 'scroll'
  },
  viewAndControlsContainer: {
    height: '80%',
    display: 'flex'
  },
  threeBox: {
    width: '50%',
    marginLeft: 2
  },
  buttonContainer: {
    display: 'flex',
    marginTop: 5
  },
  exportButton: {
    width: '100%',
    padding: 2
  },
  pdfDialogContent: {
    width: '100%',
    margin: 0,
    padding: 0,
    height: '90vh',
    overflow: 'hidden'
  },
  pdfDialog: {
    width: '100%'
  },
  pdfViewer: {
    width: '100%',
    height: '100%'
  },
  pdfDialogTitle: {
    height: 22,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  pdfDialogCloseButton: {
    marginLeft: 'auto',
    color: theme.palette.common.white
  },
  pdfDialogFooter: {
    height: 22,
    backgroundColor: theme.palette.primary.main
  }
})
