import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from '@mui/material'
import { Box } from '../../custombox/CustomBox'
import { FC } from 'react'
import { styles } from './SizeDesigner.style'
import { getExternalDimensions } from '../../../helpers/box-util'
import { FormattedMessage, useIntl } from 'react-intl'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BoxParameters } from '../../../openapi/api'

export interface SizeDesignerProps {
  boxParams: BoxParameters
  hasWriteRights: boolean
  setBoxParams: React.Dispatch<React.SetStateAction<BoxParameters>>
}

/**
 * SizeDesigner allows setting the dimensions of the box being created
 * @param boxParams The parameters for the box being created
 * @param setBoxParams React 'useState' setter to modify the state of the box being created
 * @returns React component
 */
export const SizeDesigner: FC<SizeDesignerProps> =
({ boxParams, hasWriteRights, setBoxParams }: SizeDesignerProps) => {
  const intl = useIntl()

  const { externalWidth, externalHeight, externalLength } = getExternalDimensions(boxParams)

  return (
    <Accordion sx={styles.inputBox}>
      <AccordionSummary sx={styles.dimensionsBox} expandIcon={<ExpandMoreIcon sx={styles.icon} />}>
        <Typography sx={styles.sectionHeaderTitle}>
          <FormattedMessage id='boxConfiguration.dimensions' />
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetailsContainer}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={8}
          sx={styles.materialsGrid}
        >
          <Grid item xs={1.5} />
          <Grid item xs={3.5}>
            <Box sx={styles.gridItem}>
              <FormattedMessage id='boxConfiguration.height' />
            </Box>
          </Grid>
          <Grid item xs={3.5}>
            <Box sx={styles.gridItem}>
              <FormattedMessage id='boxConfiguration.length' />
            </Box>
          </Grid>
          <Grid item xs={3.5}>
            <Box sx={styles.gridItem}>
              <FormattedMessage id='boxConfiguration.width' />
            </Box>
          </Grid>

          <Grid item xs={1.5}>
            <Box sx={styles.gridItem}>
              <FormattedMessage id='boxConfiguration.internal' />
            </Box>
          </Grid>
          <Grid item xs={3.5}>
            <TextField
              sx={styles.gridItem}
              value={boxParams.internalHeight.toString()}
              label={`${intl.formatMessage({ id: 'boxConfiguration.height' })} ${intl.formatMessage({ id: 'boxConfiguration.mm' })}`}
              type='number'
              disabled={!hasWriteRights}
              onChange={e => {
                // MUI TextField with prop label='number' returns an empty string if letters are typed
                console.log('Value:', e.target.value)
                if (e.target.value === '') {
                  return
                }
                const inputNumber = Math.max(Number(e.target.value), 0)
                setBoxParams(oldParams =>
                  ({ ...oldParams, internalHeight: inputNumber })
                )
              }}
            />
          </Grid>
          <Grid item xs={3.5}>
            <TextField
              sx={styles.gridItem}
              value={boxParams.internalLength.toString()}
              label={`${intl.formatMessage({ id: 'boxConfiguration.length' })} ${intl.formatMessage({ id: 'boxConfiguration.mm' })}`}
              type='number'
              disabled={!hasWriteRights}
              onChange={e => {
                // MUI TextField with prop label='number' returns an empty string if letters are typed
                if (e.target.value === '') {
                  return
                }
                const inputNumber = Math.max(Number(e.target.value), 0)
                setBoxParams(oldParams =>
                  ({ ...oldParams, internalLength: inputNumber })
                )
              }}
            />
          </Grid>
          <Grid item xs={3.5}>
            <TextField
              sx={styles.gridItem}
              value={boxParams.internalWidth.toString()}
              label={`${intl.formatMessage({ id: 'boxConfiguration.width' })} ${intl.formatMessage({ id: 'boxConfiguration.mm' })}`}
              type='number'
              disabled={!hasWriteRights}
              onChange={e => {
                // MUI TextField with prop label='number' returns an empty string if letters are typed
                if (e.target.value === '') {
                  return
                }
                const inputNumber = Math.max(Number(e.target.value), 0)
                setBoxParams(oldParams =>
                  ({ ...oldParams, internalWidth: inputNumber })
                )
              }}
            />
          </Grid>

          <Grid item xs={1.5}>
            <Box sx={styles.gridItem}>
              <FormattedMessage id='boxConfiguration.external' />
            </Box>
          </Grid>
          <Grid item xs={3.5}>
            <TextField
              sx={styles.gridItem}
              value={externalHeight.toString()}
              label={`${intl.formatMessage({ id: 'boxConfiguration.height' })} ${intl.formatMessage({ id: 'boxConfiguration.mm' })}`}
              type='number'
              disabled={!hasWriteRights}
              onChange={e => {
                // MUI TextField with prop label='number' returns an empty string if letters are typed
                if (e.target.value === '') {
                  return
                }
                const inputNumber = Math.max(Number(e.target.value), 0)
                setBoxParams(oldParams =>
                  ({ ...oldParams, internalHeight: inputNumber - boxParams.topThickness - boxParams.bottomThickness })
                )
              }}
            />
          </Grid>
          <Grid item xs={3.5}>
            <TextField
              sx={styles.gridItem}
              value={externalLength.toString()}
              label={`${intl.formatMessage({ id: 'boxConfiguration.length' })} ${intl.formatMessage({ id: 'boxConfiguration.mm' })}`}
              type='number'
              disabled={!hasWriteRights}
              onChange={e => {
                // MUI TextField with prop label='number' returns an empty string if letters are typed
                if (e.target.value === '') {
                  return
                }
                const inputNumber = Math.max(Number(e.target.value), 0)
                setBoxParams(oldParams =>
                  ({ ...oldParams, internalLength: inputNumber - 2 * boxParams.sidesThickness })
                )
              }}
            />
          </Grid>
          <Grid item xs={3.5}>
            <TextField
              sx={styles.gridItem}
              value={externalWidth.toString()}
              label={`${intl.formatMessage({ id: 'boxConfiguration.width' })} ${intl.formatMessage({ id: 'boxConfiguration.mm' })}`}
              type='number'
              disabled={!hasWriteRights}
              onChange={e => {
                // MUI TextField with prop label='number' returns an empty string if letters are typed
                if (e.target.value === '') {
                  return
                }
                const inputNumber = Math.max(Number(e.target.value), 0)
                setBoxParams(oldParams =>
                  ({ ...oldParams, internalWidth: inputNumber - 2 * boxParams.sidesThickness })
                )
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default SizeDesigner
