import { Product } from '../openapi/api'

export enum UserRole {
  ADMIN = 'Admin',
  DEVELOPER = 'Developer',
  SALES = 'Sales',
  CUSTOMER = 'Customer'
}

export function hasProductWriteRights (product: Product, userRole: UserRole): boolean {
  if (userRole === UserRole.ADMIN || userRole === UserRole.DEVELOPER) {
    return true
  }
  if (userRole === UserRole.CUSTOMER) {
    return false
  }
  if (product.status === 'design' || product.status === 'develop') {
    return true
  }
  return false
}

export function hasAdminRights (role: UserRole): boolean {
  return role === UserRole.ADMIN
}

export function hasDeveloperRights (role: UserRole): boolean {
  switch (role) {
    case UserRole.ADMIN:
      return true
    case UserRole.DEVELOPER:
      return true
    default:
      return false
  }
}

export function hasSalesRights (role: UserRole): boolean {
  return role !== UserRole.CUSTOMER
}
