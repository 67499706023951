import { Text, View } from '@react-pdf/renderer'
import { box, styles } from './BoxDiagramView.style'

export interface BoxDiagramViewProps {
  text: string
  width: number
  height: number
  drawingOffset: [number?, number?, number?, number?] // top, left, bottom, right
  drawingSize: [number, number] // width, height
}

interface MeasurementLineProps {
  length: number
  placement: 'top' | 'left'
  subLength?: number
}

/**
 * Create measurement line either to the left or top of the diagram box
 */
const MeasurementLine = ({ length, placement, subLength }: MeasurementLineProps): JSX.Element => {
  const offset = subLength !== undefined ? -10 : -30
  const lengthPercentage = subLength !== undefined ? '75%' : '100%'
  if (placement === 'left') {
    return (
      <View style={{ ...styles.leftMeasurementLineContainer, left: offset, height: lengthPercentage }}>
        <View style={styles.leftFullLengthLine}>
          <Text style={styles.leftFullLengthText}>
            {length}
          </Text>
        </View>
        {subLength !== undefined &&
          <View style={styles.leftSubLengthLine}>
            <Text style={styles.leftSubLengthText}>
              {subLength}
            </Text>
          </View>}
      </View>
    )
  } else {
    return (
      <View style={{ ...styles.rightMeasurementContainer, top: offset, width: lengthPercentage }}>
        <View style={styles.rightFullLengthLine}>
          <Text style={styles.rightFullLengthText}>
            {length}
          </Text>
        </View>
        {subLength !== undefined &&
          <View style={styles.rightSubLengthLine}>
            <Text style={styles.rightSubLengthText}>
              {subLength}
            </Text>
          </View>}
      </View>
    )
  }
}

/**
 * Displays a diagram of a box with a certain width and height, along with measurement lines to indicate lengths
 */
export const BoxDiagramView = ({ text, width, height, drawingOffset, drawingSize }: BoxDiagramViewProps): JSX.Element => {
  return (
    <View style={{ ...styles.container, top: drawingOffset[0], left: drawingOffset[1], bottom: drawingOffset[2], right: drawingOffset[3] }}>
      {/* Top measurement lines */}
      <MeasurementLine length={width} placement='top' />
      <MeasurementLine length={width - 50} placement='top' subLength={50} />
      {/* Left measurement lines */}
      <MeasurementLine length={height} placement='left' />
      <MeasurementLine length={height - 50} placement='left' subLength={50} />
      {/* Box outline with text */}
      <View style={{ ...box(drawingSize[0], drawingSize[1]).style }}>
        <Text style={styles.text}>
          {text}
        </Text>
      </View>
    </View>
  )
}

export default BoxDiagramView
