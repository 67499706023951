import { colors, createStyles } from '../../theme'

export const styles = createStyles({
  root: {
  },
  appBar: {
    position: 'fixed',
    backgroundColor: colors.brandBase
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'left',
    paddingRight: 0
  },
  title: {
    color: colors.white,
    cursor: 'pointer'
  },
  content: {
    marginTop: '80px',
    backgroundColor: colors.white
  },
  profile: {
    position: 'absolute',
    right: 10,
    lineHeight: '60px'
  },
  userInfo: {
    marginLeft: 5
  }
})
