import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createMigrate, FLUSH, MigrationManifest, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import authReducer, { authInitialState, AuthState } from '../features/auth/authSlice'
import storage from 'redux-persist/lib/storage'

const persistVersion = 1
const migrations: MigrationManifest = {}
migrations[persistVersion] = () => ({
  _persist: {
    version: 0,
    rehydrated: false
  }
})

// persist configurations
const persistConfig = {
  key: 'root',
  version: persistVersion,
  storage,
  migrate: createMigrate(migrations, { debug: false })
}

const rootReducer = combineReducers({
  auth: authReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

// If using Redux-Persist, you should specifically ignore all the action types it dispatches
// https://redux-toolkit.js.org/usage/usage-guide
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

export interface State {
  auth: AuthState
}

export const initialState: State = {
  auth: authInitialState
}

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type
export type AppDispatch = typeof store.dispatch

export default store
