import { createStyles } from '../../theme'

export const styles = createStyles({
  content: {
    margin: 'auto',
    marginTop: 18,
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: 900,
    maxWidth: 1700,
  },
  headerText: {
    marginBottom: 6,
  },
  searchFieldButtonContainer: {
    display: 'flex',
  },
  searchField: {
    borderStyle: 'solid',
    padding: 1,
  },
  buttonBox: {
    float: 'right',
    borderStyle: 'solid',
    padding: 2,
  },
  tableContainer: {
    marginTop: 2,
  },
  table: {
    width: '100%',
    display: 'table',
  },
  tableRow: {
    display: 'table-row',
  },
  tableBodyRow: {
    cursor: 'pointer',
    '&:last-child td, &:last-child th': { border: 0 },
  },
  titleCell: {
    width: '35%',
    fontWeight: 'bold',
  },
  descriptionCell: {
    width: '35%',
    fontWeight: 'bold',
  },
  dateCell: {
    width: '20%',
    fontWeight: 'bold',
  },
  editCell: {
    width: '10%',
    fontWeight: 'bold',
  },
  titleCellBody: {
    width: '35%',
  },
  descriptionCellBody: {
    width: '35%',
  },
  dateCellBody: {
    width: '20%',
  },
  editCellBody: {
    width: '10%',
  },
  createButton: {
    paddingBottom: '4px',
    paddingTop: 1,
    paddingLeft: 1,
    paddingRight: 2,
  },
  createButtonContainer: {
    fontWeight: 'bold',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
  },
  addIcon: {
    paddingBottom: '3px',
    marginRight: 1,
  },
})
