/* tslint:disable */
/* eslint-disable */
/**
 * parbox BFF
 * Backend for frontend API for parbox app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BoxParametersClips } from './BoxParametersClips';
import {
    BoxParametersClipsFromJSON,
    BoxParametersClipsFromJSONTyped,
    BoxParametersClipsToJSON,
} from './BoxParametersClips';
import type { MaterialTypes } from './MaterialTypes';
import {
    MaterialTypesFromJSON,
    MaterialTypesFromJSONTyped,
    MaterialTypesToJSON,
} from './MaterialTypes';

/**
 * 
 * @export
 * @interface BoxParameters
 */
export interface BoxParameters {
    /**
     * 
     * @type {number}
     * @memberof BoxParameters
     */
    internalHeight: number;
    /**
     * 
     * @type {number}
     * @memberof BoxParameters
     */
    internalLength: number;
    /**
     * 
     * @type {number}
     * @memberof BoxParameters
     */
    internalWidth: number;
    /**
     * 
     * @type {MaterialTypes}
     * @memberof BoxParameters
     */
    topMaterial: MaterialTypes;
    /**
     * 
     * @type {MaterialTypes}
     * @memberof BoxParameters
     */
    bottomMaterial: MaterialTypes;
    /**
     * 
     * @type {MaterialTypes}
     * @memberof BoxParameters
     */
    sidesMaterial: MaterialTypes;
    /**
     * 
     * @type {number}
     * @memberof BoxParameters
     */
    topThickness: number;
    /**
     * 
     * @type {number}
     * @memberof BoxParameters
     */
    bottomThickness: number;
    /**
     * 
     * @type {number}
     * @memberof BoxParameters
     */
    sidesThickness: number;
    /**
     * 
     * @type {BoxParametersClips}
     * @memberof BoxParameters
     */
    clips: BoxParametersClips;
}

/**
 * Check if a given object implements the BoxParameters interface.
 */
export function instanceOfBoxParameters(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "internalHeight" in value;
    isInstance = isInstance && "internalLength" in value;
    isInstance = isInstance && "internalWidth" in value;
    isInstance = isInstance && "topMaterial" in value;
    isInstance = isInstance && "bottomMaterial" in value;
    isInstance = isInstance && "sidesMaterial" in value;
    isInstance = isInstance && "topThickness" in value;
    isInstance = isInstance && "bottomThickness" in value;
    isInstance = isInstance && "sidesThickness" in value;
    isInstance = isInstance && "clips" in value;

    return isInstance;
}

export function BoxParametersFromJSON(json: any): BoxParameters {
    return BoxParametersFromJSONTyped(json, false);
}

export function BoxParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoxParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'internalHeight': json['internalHeight'],
        'internalLength': json['internalLength'],
        'internalWidth': json['internalWidth'],
        'topMaterial': MaterialTypesFromJSON(json['topMaterial']),
        'bottomMaterial': MaterialTypesFromJSON(json['bottomMaterial']),
        'sidesMaterial': MaterialTypesFromJSON(json['sidesMaterial']),
        'topThickness': json['topThickness'],
        'bottomThickness': json['bottomThickness'],
        'sidesThickness': json['sidesThickness'],
        'clips': BoxParametersClipsFromJSON(json['clips']),
    };
}

export function BoxParametersToJSON(value?: BoxParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'internalHeight': value.internalHeight,
        'internalLength': value.internalLength,
        'internalWidth': value.internalWidth,
        'topMaterial': MaterialTypesToJSON(value.topMaterial),
        'bottomMaterial': MaterialTypesToJSON(value.bottomMaterial),
        'sidesMaterial': MaterialTypesToJSON(value.sidesMaterial),
        'topThickness': value.topThickness,
        'bottomThickness': value.bottomThickness,
        'sidesThickness': value.sidesThickness,
        'clips': BoxParametersClipsToJSON(value.clips),
    };
}

