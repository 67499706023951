import { Document, Page, Text, View, Image } from '@react-pdf/renderer'
import { getExternalDimensions, getPanelDimensions } from '../../helpers/box-util'
import { BoxParameters } from '../../openapi/api'
import BoxDiagramView from './boxdiagramview/BoxDiagramView'
import { styles } from './BoxPdf.style'
import InfoBoxView from './infoboxview/InfoBoxView'

const logoUrl = '/logo.png'

export interface BoxPdfProps {
  header: {
    name: string
    text: string
  }
  boxParameters: BoxParameters
  imageSrc?: string
}

/**
 * PDF document for exporting a box
 * OBS: Internationalization does not work out of the box here
 */
export const BoxPdf = ({ boxParameters, imageSrc, header }: BoxPdfProps): JSX.Element => {
  const { externalHeight, externalLength, externalWidth } = getExternalDimensions(boxParameters)
  const { sidePanel, topPanel, endPanel } = getPanelDimensions(boxParameters)
  const clipCount = (boxParameters.clips.offsets.lengthWise.length + boxParameters.clips.offsets.widthWise.length + boxParameters.clips.offsets.heightWise.length) * 2 * 4 // Mirrored on side (* 2) on all four edges (* 4)

  return (
    <Document>
      <Page orientation='landscape'>
        <View style={styles.pageHeader}>
          <View style={styles.headerTopContainer}>
            <View style={styles.headerLogoContainer}>
              <Image style={styles.logo} src={logoUrl} />
              <Text>
                {header.name}
              </Text>
            </View>
            <Text style={styles.headerText}>
              {header.text}
            </Text>
          </View>
          <Text>
            4-WAY ISOMETRIC VIEW
          </Text>
        </View>
        <View style={styles.pageContent}>
          {imageSrc !== undefined &&
            <Image
              style={styles.isometricImage}
              src={imageSrc}
            />}
          <InfoBoxView
            rows={[
              ['Client name', 'Absortech'],
              ['Project Code', '303910A'],
              ['Clip-Lok reference', 'NH'],
              [undefined, 'L x W x H'],
              ['External dimensions', `${externalLength} x ${externalWidth} x ${externalHeight} mm `],
              [undefined, undefined],
              ['Clip type', 'SMALL'],
              ['Total nr. of clips', `${clipCount}`],
              [undefined, undefined],
              ['Pallet', 'FOUR WAY']
            ]}
          />
        </View>
      </Page>
      <Page orientation='landscape'>
        <View style={styles.pageHeader}>
          <View style={styles.headerTopContainer}>
            <View style={styles.headerLogoContainer}>
              <Image style={styles.logo} src={logoUrl} />
              <Text>
                {header.name}
              </Text>
            </View>
            <Text style={styles.headerText}>
              {header.text}
            </Text>
          </View>
          <Text>
            ALL PANELS VIEW
          </Text>
        </View>
        <View style={styles.pageContent}>
          <BoxDiagramView
            text='Bund / Låg'
            width={topPanel.x}
            height={topPanel.y}
            drawingOffset={[75, 150]}
            drawingSize={[200, 150]}
          />
          <BoxDiagramView
            text='Ende'
            width={endPanel.x}
            height={endPanel.y}
            drawingOffset={[undefined, 150, 25]}
            drawingSize={[150, 175]}
          />
          <BoxDiagramView
            text='Side'
            width={sidePanel.x}
            height={sidePanel.y}
            drawingOffset={[75, undefined, undefined, 150]}
            drawingSize={[200, 200]}
          />
          <InfoBoxView
            rows={[
              ['Client Name', 'Absortech'],
              ['Project Code', '303910A'],
              [undefined, undefined],
              ['Top material', boxParameters.topMaterial],
              ['Top thickness', `${boxParameters.topThickness} mm`],
              [undefined, undefined],
              ['Side/end material', boxParameters.sidesMaterial],
              ['Side/end thickness', `${boxParameters.sidesThickness} mm`],
              [undefined, undefined],
              ['Bottom material', boxParameters.bottomMaterial],
              ['Bottom thickness', `${boxParameters.bottomThickness} mm`]
            ]}
          />
        </View>
      </Page>
    </Document>
  )
}

export default BoxPdf
