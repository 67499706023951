import { createStyles } from '../../../theme'

export const styles = createStyles({
  infoBox: {
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
    padding: '8px',
    position: 'absolute',
    width: 300,
    bottom: 0,
    right: 0,
    fontSize: '12px'
  },
  row: {
    flexDirection: 'row'
  },
  rowName: {
    width: '50%'
  }
})
