/* eslint-disable  @typescript-eslint/return-await */

import { createContext, ReactNode, useContext } from 'react'
import { useQuery, UseQueryResult, useMutation, UseMutationResult, useQueryClient } from 'react-query'

import { DefaultApi } from '../openapi/api'
import { Product } from '../openapi/api/models'
import { useAppSelector } from '../state/hooks'

/**
 * ApiType specifies the available type of api calls that can be used from the provider.
 */
export interface ApiType {
  useProductsQuery: () => UseQueryResult<{[key: string]: Product}, Error>
  useProductUpdateMutation: (productId: string) => UseMutationResult<string, Error, Product>
}

/**
 * ApiProviderProps specifies props that can be passed to the provider.
 */
interface ApiProviderProps {
  children?: ReactNode
  apiUrl: string
}

export interface TestData {
  text: string
}

// TODO: Fix types, avoid undefined! initial value?
// https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947
// eslint-disable-next-line
const ApiContext = createContext<ApiType>(undefined!)

/**
 * Exposes useApi handle that can be used in components to make call to the api.
 * @returns ApiType object.
 */
export const useApi = (): ApiType => {
  return useContext(ApiContext)
}

/**
 * ApiProvider provides access to various queries and actions on the BFF.
 * @param param0 Children and api url.
 * @returns ApiProvider
 */
export const ApiProvider = ({
  children,
  apiUrl
}: ApiProviderProps): JSX.Element => {
  const queryClient = useQueryClient()
  const api = new DefaultApi()
  const csrf: string = useAppSelector((state) => state.auth.csrf)

  const useProductsQuery = (): UseQueryResult<{[key: string]: Product}, Error> =>
    useQuery(['products'], async () =>
      api.getProducts()
        .then(data => { // This should probably be done on backend. But this was faster for me to get done
          const uuidProductMap: {[key: string]: Product} = {}
          for (const product of data) {
            uuidProductMap[product.uuid] = product
          }
          return uuidProductMap
        }))

  const updateProduct = async (productId: string, product: Product): Promise<string> => {
    return api.updateProduct({
      productUuid: productId,
      product: product,
      xCSRFToken: csrf
    })
  }

  const useProductUpdateMutation = (productId: string): UseMutationResult<string, Error, Product> =>
    useMutation(['productUpdate', productId], async (product: Product) => {
      return updateProduct(productId, product)
    }, {
      onSuccess: () => {
        void queryClient.invalidateQueries('products')
      }
    })

  return (
    <ApiContext.Provider value={{ useProductsQuery, useProductUpdateMutation }}>
      {children}
    </ApiContext.Provider>
  )
}
