import { FC } from 'react'

import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material'

import { styles } from './Layout.style'
import { FormattedMessage } from 'react-intl'
import { useAppSelector } from '../../state/hooks'
import { useNavigate } from 'react-router'
import { paths } from '../../paths'

export interface LayoutProps {
  children: JSX.Element[] | JSX.Element
  title?: string | undefined
  logout?: () => void
}

const defaultProps: LayoutProps = {
  children: <></>,
  title: 'Parbox 3.0',
  logout: undefined
}

/**
 * Layout creates header and footer for app.
 * @param props Properties for the component.
 * @returns React component.
 */
export const Layout: FC<LayoutProps> = ({ children, title = 'Parbox 3.0', logout = undefined }: LayoutProps) => {
  const { username, role } = useAppSelector(state => state.auth.user)
  const { isAuthenticated } = useAppSelector(state => state.auth)
  const navigate = useNavigate()

  return (
    <Box component='div' sx={styles.root}>
      <AppBar position='absolute' color='primary' sx={styles.appBar} data-testid='app-bar'>
        <Toolbar
          sx={styles.toolbar}
        >
          <Typography
            variant='h1'
            sx={styles.title}
            onClick={() => navigate(paths.home)}
          >
            {title}
          </Typography>
          {isAuthenticated &&
            <Typography sx={styles.userInfo}>
              {`Username: ${username}, Role: ${role}`}
            </Typography>}
        </Toolbar>
        <Box component='div' sx={styles.profile}>
          {logout !== undefined &&
            <Button
              variant='text'
              sx={{ color: 'white' }}
              onClick={logout}
            >
              <FormattedMessage id='general.logout' />
            </Button>}
        </Box>
      </AppBar>
      <Box component='div' sx={styles.content}>
        {children}
      </Box>
    </Box>
  )
}

Layout.defaultProps = defaultProps

export default Layout
