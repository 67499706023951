import { createStyles } from '../../theme'

export const styles = createStyles({
  canvas: {
    width: '100%',
    height: '100%'
  },
  isometricCanvas: {
    visibility: 'hidden',
    position: 'absolute',
    width: 728, // TODO: should follow main canvas, but quickfix for now
    height: 938.875 // TODO: should follow main canvas, but quickfix for now
  }
})
