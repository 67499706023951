import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { styles } from './ClipDesigner.style'
import { getExternalDimensions } from '../../../helpers/box-util'
import { FormattedMessage, useIntl } from 'react-intl'
import { ClipList } from './cliplist/ClipList'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BoxParameters } from '../../../openapi/api'

export interface ClipDesignerProps {
  boxParams: BoxParameters
  hasWriteRights: boolean
  setBoxParams: React.Dispatch<React.SetStateAction<BoxParameters>>
}

/**
 * Clipdesigner allows selected the number, type and placements of clips for the box
 * @param boxParams The parameters for the box being created
 * @param setBoxParams React 'useState' setter to modify the state of the box being created
 * @returns React component
 */
export const ClipDesigner: FC<ClipDesignerProps> =
({ boxParams, hasWriteRights, setBoxParams }: ClipDesignerProps) => {
  const intl = useIntl()

  const { externalWidth, externalHeight, externalLength } = getExternalDimensions(boxParams)

  const minClipDistance = 30 // TODO: Move and change when more is known about limitations
  const minCornerDist = 50 // TODO: Move and change when more is known about limitations

  const [hasEditedAxis, setHasEditedAxis] = useState({
    heightWise: false,
    lengthWise: false,
    widthWise: false
  })

  const changeClipOffsets =
  (newClips: number[], axis: keyof BoxParameters['clips']['offsets']): void => {
    setBoxParams(oldBoxParams => {
      const newBoxParams = { ...oldBoxParams }
      newBoxParams.clips.offsets[axis] = newClips
      return newBoxParams
    })
    setHasEditedAxis(oldHasEditedAxis => {
      const newHasEditedAxis = { ...oldHasEditedAxis }
      newHasEditedAxis[axis] = true
      return newHasEditedAxis
    })
  }

  const updateClipPlacements =
  (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    axis: keyof BoxParameters['clips']['offsets']): void => {
    const input = event.target.value
    if (input === '') {
      return
    }
    let newClipCount = Number(input)
    if (newClipCount <= 0) {
      setHasEditedAxis(oldHasEditedAxis => {
        const newHasEditedAxis = { ...oldHasEditedAxis }
        newHasEditedAxis[axis] = false // If we start adding clips, behave as if no custom clip placements have been made
        return newHasEditedAxis
      })
      setBoxParams(oldBoxParams => {
        const newBoxParams = { ...oldBoxParams }
        newBoxParams.clips.offsets[axis] = []
        return newBoxParams
      })
      return
    }
    // Non-zero number of clips
    const oldClipCount = boxParams.clips.offsets[axis].length * 2
    const remainder = newClipCount % 2
    if (remainder !== 0) {
      if (oldClipCount < newClipCount) {
        newClipCount = Math.round(newClipCount + remainder)
      } else {
        newClipCount = Math.round(newClipCount - remainder)
      }
    }
    // oldClipCount and newClipCount are now both even integers
    if (oldClipCount === newClipCount) {
      return
    }

    let dist = 0
    switch (axis) {
      case 'heightWise':
        dist = externalHeight
        break
      case 'widthWise':
        dist = externalWidth
        break
      case 'lengthWise':
        dist = externalLength
        break
    }

    const hasEdited = hasEditedAxis[axis]
    if (hasEdited) {
      console.warn('User changes are being overridden. TODO: How to handle changing number of clips after user changes to offsets')
    }
    const newClips = computeEvenlySpacedClips(newClipCount, dist)
    setBoxParams(oldBoxParams => {
      const newBoxParams = { ...oldBoxParams }
      newBoxParams.clips.offsets[axis] = newClips
      return newBoxParams
    })
  }

  const computeEvenlySpacedClips = (clipCount: number, externalDist: number): number[] => {
    if (clipCount % 2 !== 0 || clipCount < 0) {
      throw new Error(`computeEvenlySpacedClips was called with non-even or negative value for # of clips (${clipCount})`)
    }
    if (clipCount === 0) {
      return []
    }

    let interval = externalDist / (clipCount + 1)
    let lastClipDist = 0
    const clips: number[] = []

    // Check if we are close to the maximum number of clips
    // If we are, recompute interval with as little space to the edges as possible
    if (interval < minCornerDist || interval < minClipDistance) {
      interval = (externalDist - 2 * minCornerDist) / (clipCount - 1)
      lastClipDist = Math.ceil(minCornerDist)
      clips.push(lastClipDist)
    }

    // If the interval is smaller than the minimum clip distance
    // the number of clips cannot be supported on a box of the given size
    if (interval < minClipDistance) {
      return computeEvenlySpacedClips(clipCount - 2, externalDist)
    }
    while (clips.length < clipCount / 2) {
      lastClipDist += interval
      clips.push(Math.round(lastClipDist))
    }

    return clips
  }

  return (
    <Accordion sx={styles.inputBox}>
      <AccordionSummary sx={styles.clipsBox} expandIcon={<ExpandMoreIcon sx={styles.icon} />}>
        <Typography sx={styles.sectionHeaderTitle}>
          <FormattedMessage id='boxConfiguration.clips' />
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetailsContainer}>
        <Grid container rowSpacing={2} columnSpacing={8} sx={styles.materialsGrid}>
          <Grid item xs={4}>
            <FormattedMessage id='boxConfiguration.heightwise' />
          </Grid>
          <Grid item xs={4}>
            <FormattedMessage id='boxConfiguration.lengthwise' />
          </Grid>
          <Grid item xs={4}>
            <FormattedMessage id='boxConfiguration.widthwise' />
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={styles.gridItem}
              value={(boxParams.clips.offsets.heightWise.length * 2).toString()}
              label={intl.formatMessage({ id: 'boxConfiguration.numberOfClips' })}
              disabled={!hasWriteRights}
              type='number'
              onChange={e => updateClipPlacements(e, 'heightWise')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={styles.gridItem}
              value={(boxParams.clips.offsets.lengthWise.length * 2).toString()}
              label={intl.formatMessage({ id: 'boxConfiguration.numberOfClips' })}
              disabled={!hasWriteRights}
              type='number'
              onChange={e => updateClipPlacements(e, 'lengthWise')}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={styles.gridItem}
              value={(boxParams.clips.offsets.widthWise.length * 2).toString()}
              label={intl.formatMessage({ id: 'boxConfiguration.numberOfClips' })}
              disabled={!hasWriteRights}
              type='number'
              onChange={e => updateClipPlacements(e, 'widthWise')}
            />
          </Grid>
          <Grid item xs={4}>
            <ClipList
              clips={boxParams.clips.offsets.heightWise}
              externalDist={externalHeight}
              hasWriteRights={hasWriteRights}
              onClipsChange={(clips: number[]) => changeClipOffsets(clips, 'heightWise')}
            />
          </Grid>
          <Grid item xs={4}>
            <ClipList
              clips={boxParams.clips.offsets.lengthWise}
              externalDist={externalLength}
              hasWriteRights={hasWriteRights}
              onClipsChange={(clips: number[]) => changeClipOffsets(clips, 'lengthWise')}
            />
          </Grid>
          <Grid item xs={4}>
            <ClipList
              clips={boxParams.clips.offsets.widthWise}
              externalDist={externalWidth}
              hasWriteRights={hasWriteRights}
              onClipsChange={(clips: number[]) => changeClipOffsets(clips, 'widthWise')}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ClipDesigner
