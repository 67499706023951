import { createStyles, colors } from '../../theme'

export const styles = createStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 0,
    width: '300px',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 0,
    width: '300px',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    paddingTop: 10,
    marginTop: 4,
    marginLeft: 0,
    color: colors.textBase,
    height: 'fit-content'
  },
  loginButton: {
    height: '55px',
    width: '300px',
    marginTop: 2,
    marginLeft: 0,
    borderRadius: 2,
    background: colors.brandBase,
    '&:hover': {
      background: colors.brandBase
    }
  },
  loginText: {
    position: 'absolute',
    marginTop: 0,
    fontSize: 22,
    color: colors.textBase
  },
  input: {
    width: 300,
    borderRadius: 2,
    overflow: 'hidden',
    marginTop: 2
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10
  },
  hidden: {
    visibility: 'hidden'
  },
  errorText: {
    color: 'red'
  }
})
