import { createStyles } from '../../../theme'

export const styles = createStyles({
  container: {
    position: 'absolute'
  },
  text: {
    margin: 'auto'
  },
  leftMeasurementLineContainer: {
    position: 'absolute',
    bottom: 0,
    borderLeft: '1px solid red',
    width: 10
  },
  leftFullLengthLine: {
    position: 'absolute',
    borderTop: '1px solid red',
    borderBottom: '1px solid red',
    width: 10,
    height: '100%',
    left: -5,
    top: 0
  },
  leftFullLengthText: {
    position: 'absolute',
    fontSize: '12px',
    transform: 'rotate(-90)',
    left: -10,
    bottom: '50%'
  },
  leftSubLengthLine: {
    position: 'absolute',
    borderTop: '1px solid red',
    width: 10,
    height: '25%',
    left: -5,
    bottom: 0
  },
  leftSubLengthText: {
    position: 'absolute',
    fontSize: '12px',
    transform: 'rotate(-90)',
    left: -10,
    bottom: '15%' // Not important just hardcoded
  },
  rightMeasurementContainer: {
    position: 'absolute',
    left: 0,
    borderTop: '1px solid red',
    height: 10
  },
  rightFullLengthLine: {
    position: 'absolute',
    borderRight: '1px solid red',
    borderLeft: '1px solid red',
    height: 10,
    width: '100%',
    top: -5,
    left: 0
  },
  rightFullLengthText: {
    position: 'absolute',
    fontSize: '12px',
    top: -10,
    left: '50%'
  },
  rightSubLengthLine: {
    position: 'absolute',
    borderRight: '1px solid red',
    height: 10,
    width: '25%',
    top: -5,
    left: 0
  },
  rightSubLengthText: {
    position: 'absolute',
    fontSize: '12px',
    top: -10,
    left: '15%' // Hardcoded
  }
})

export const box = (width: number, height: number): { style: any } => {
  return createStyles({
    style: {
      border: '1px solid black',
      width: width,
      height: height
    }
  })
}
