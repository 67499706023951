/* tslint:disable */
/* eslint-disable */
/**
 * parbox BFF
 * Backend for frontend API for parbox app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductStatus = {
    Design: 'design',
    Develop: 'develop',
    Production: 'production'
} as const;
export type ProductStatus = typeof ProductStatus[keyof typeof ProductStatus];


export function ProductStatusFromJSON(json: any): ProductStatus {
    return ProductStatusFromJSONTyped(json, false);
}

export function ProductStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductStatus {
    return json as ProductStatus;
}

export function ProductStatusToJSON(value?: ProductStatus | null): any {
    return value as any;
}

