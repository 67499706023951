/* tslint:disable */
/* eslint-disable */
/**
 * parbox BFF
 * Backend for frontend API for parbox app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClipTypes = {
    Small: 'small',
    Medium: 'medium',
    Large: 'large'
} as const;
export type ClipTypes = typeof ClipTypes[keyof typeof ClipTypes];


export function ClipTypesFromJSON(json: any): ClipTypes {
    return ClipTypesFromJSONTyped(json, false);
}

export function ClipTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClipTypes {
    return json as ClipTypes;
}

export function ClipTypesToJSON(value?: ClipTypes | null): any {
    return value as any;
}

