import { createStyles } from '../../../../theme'

export const styles = createStyles({
  listItem: {
    marginTop: 2
  },
  listcontainer: {
    overflowY: 'auto',
    marginBottom: 2
  }
})
