import { createTheme, SxProps, Theme } from '@mui/material'

interface Styles {
  [name: string]: SxProps<Theme>
}

export const createStyles = <T extends Styles>(styles: T): T => styles

export const colors = {
  brandBase: '#01528D',
  textBase: '#001F4F',
  white: '#ffffff'
}

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Nunito',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif'
    ].join(',')
  },
  palette: {
    primary: {
      main: colors.brandBase
    }
  },
  components: {
    // TODO: Maybe define custom ones that match UI style guide
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 32,
          fontWeight: 700,
          color: colors.textBase
        },
        h2: {
          fontSize: 48,
          fontWeight: 600,
          color: colors.textBase
        },
        h3: {
          fontSize: 24,
          fontWeight: 700
        },
        h4: {
          fontSize: 18,
          fontWeight: 700
        },
        h5: {
          fontSize: 16,
          fontWeight: 600
        },
        body1: {
          fontSize: 16,
          fontWeight: 600
        },
        body2: {
          fontSize: 14,
          fontWeight: 500
        }
      }
    }
  }
})
