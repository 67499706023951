/* tslint:disable */
/* eslint-disable */
/**
 * parbox BFF
 * Backend for frontend API for parbox app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Product,
} from '../models';
import {
    ProductFromJSON,
    ProductToJSON,
} from '../models';

export interface UpdateProductRequest {
    productUuid: string;
    xCSRFToken: string;
    product: Product;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Get list of products
     */
    async getProductsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Product>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductFromJSON));
    }

    /**
     * Get list of products
     */
    async getProducts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Product>> {
        const response = await this.getProductsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update a product
     */
    async updateProductRaw(requestParameters: UpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.productUuid === null || requestParameters.productUuid === undefined) {
            throw new runtime.RequiredError('productUuid','Required parameter requestParameters.productUuid was null or undefined when calling updateProduct.');
        }

        if (requestParameters.xCSRFToken === null || requestParameters.xCSRFToken === undefined) {
            throw new runtime.RequiredError('xCSRFToken','Required parameter requestParameters.xCSRFToken was null or undefined when calling updateProduct.');
        }

        if (requestParameters.product === null || requestParameters.product === undefined) {
            throw new runtime.RequiredError('product','Required parameter requestParameters.product was null or undefined when calling updateProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xCSRFToken !== undefined && requestParameters.xCSRFToken !== null) {
            headerParameters['X-CSRFToken'] = String(requestParameters.xCSRFToken);
        }

        const response = await this.request({
            path: `/products/{productUuid}`.replace(`{${"productUuid"}}`, encodeURIComponent(String(requestParameters.productUuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductToJSON(requestParameters.product),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update a product
     */
    async updateProduct(requestParameters: UpdateProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
