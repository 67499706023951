import { FC, useRef, useState } from 'react'
import { Box } from '../custombox/CustomBox'
import { styles } from './FrontPage.style'
import Layout from '../layout/Layout'
import MaterialsDesigner from '../boxdesigners/materialsdesigner/MaterialsDesigner'
import SizeDesigner from '../boxdesigners/sizedesigner/SizeDesigner'
import ClipDesigner from '../boxdesigners/clipdesigner/ClipDesigner'
import BoxVisualizer from '../boxvisualizer/BoxVisualizer'
import BoxPdf from '../boxpdf/BoxPdf'
import { boxParamsToDxfString } from '../../helpers/export-util'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { PDFViewer } from '@react-pdf/renderer'
import { Close } from '@mui/icons-material'
import { useProducts } from '../../api/hooks/useProductsQuery'
import { BoxParameters, Product } from '../../openapi/api'
import { useNavigate, useParams } from 'react-router'
import { paths } from '../../paths'
import { useUpdateProduct } from '../../api/hooks/useUpdateProductMutation'
import { hasProductWriteRights } from '../../helpers/user-util'
import { useAppSelector } from '../../state/hooks'

export interface FrontPageProps {
  logout: () => void
}
/**
 * FrontPage provides options to navigate to various parts of the app.
 * @param props Properties for the component.
 * @returns React component.
 */
export const FrontPage: FC<FrontPageProps> = ({ logout }: FrontPageProps) => {
  const intl = useIntl()
  const productId = useParams().id ?? ''
  const navigate = useNavigate()

  const { data: products, error: productError } = useProducts()
  const { role } = useAppSelector((state) => state.auth.user)

  const { mutateAsync: updateProductMutation } = useUpdateProduct(productId)
  const product: Product | undefined = products?.[productId]

  const hasWriteRights = product !== undefined
    ? hasProductWriteRights(product, role)
    : false

  const [boxParams, setBoxParams] = useState<BoxParameters | undefined>(undefined)
  // Typescript and react usestate types are not as clever as they should be so this casting hack
  // is needed to simplify child components using 'setBoxParams'
  const setBoxParams2 = setBoxParams as React.Dispatch<React.SetStateAction<BoxParameters>>

  const [openPdf, setOpenPdf] = useState(false)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [imageUrl, setImageUrl] = useState<string>('/logo.png')

  const downloadDxf = (): void => {
    if (boxParams === undefined) {
      return
    }
    const dxfString = boxParamsToDxfString(boxParams)
    const blob = new Blob([dxfString])
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = 'parbox3_temp_dxfname.dxf'
    link.href = blobUrl
    link.click()
  }

  const handleOpenPdf = (): void => {
    if (canvasRef.current != null) {
      const canvasImgUrl = canvasRef.current.toDataURL('image/png')
      setImageUrl(canvasImgUrl)
    }
    setOpenPdf(true)
  }

  const onSaveClick = (): void => {
    if (product === undefined || boxParams === undefined) {
      console.warn('onSave Called when product or boxParams are undefined')
      return
    }
    const newProd = { ...product, box: { uuid: product.uuid, boxParameters: boxParams } }
    void updateProductMutation(newProd,
      {
        onSuccess: () => {
          alert('Changes to product saved')
        },
        onError: (e) => {
          console.warn('Saving product failed')
          console.error(e)
          alert('Saving product failed')
        }
      })
  }

  if (boxParams === undefined && product !== undefined) {
    setBoxParams(product.box.boxParameters)
  }

  if (productId === undefined) {
    navigate(paths.home)
  }

  if (productError != null) {
    console.warn('TODO: Properly handle product errors')
    console.error(productError)
    navigate(paths.home)
  }

  if (products === undefined || boxParams === undefined) {
    return (
      <div>
        Loading..
      </div>
    )
  }

  return (
    <Layout logout={logout}>
      <Container maxWidth='xl' sx={styles.container}>
        <Box sx={styles.viewAndControlsContainer}>
          <Box sx={styles.materialsAndSizeContainer}>
            <MaterialsDesigner
              boxParams={boxParams}
              hasWriteRights={hasWriteRights}
              setBoxParams={setBoxParams2}
            />
            <SizeDesigner
              boxParams={boxParams}
              hasWriteRights={hasWriteRights}
              setBoxParams={setBoxParams2}
            />
            <ClipDesigner
              boxParams={boxParams}
              hasWriteRights={hasWriteRights}
              setBoxParams={setBoxParams2}
            />
          </Box>
          <Paper elevation={4} sx={styles.threeBox}>
            <BoxVisualizer boxParams={boxParams} ref={canvasRef} />
          </Paper>
        </Box>
        <Stack direction='row' spacing={4} sx={styles.buttonContainer}>
          {hasWriteRights &&
            <Button
              sx={styles.exportButton}
              variant='contained'
              onClick={onSaveClick}
            >
              <FormattedMessage id='general.save' />
            </Button>}
          <Button
            sx={styles.exportButton}
            variant='contained'
            onClick={downloadDxf}
          >
            <FormattedMessage id='export.dxf' />
          </Button>
          <Button
            sx={styles.exportButton}
            variant='contained'
            onClick={handleOpenPdf}
          >
            <FormattedMessage id='export.pdf' />
          </Button>
        </Stack>
      </Container>
      <Dialog
        sx={styles.pdfDialog}
        fullWidth
        maxWidth='lg'
        open={openPdf}
      >
        <DialogTitle sx={styles.pdfDialogTitle}>
          <Typography>
            <FormattedMessage id='export.pdf' />
          </Typography>
          <IconButton sx={styles.pdfDialogCloseButton} onClick={() => setOpenPdf(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent style={styles.pdfDialogContent}>
          <PDFViewer style={styles.pdfViewer}>
            <BoxPdf
              header={{
                name: intl.formatMessage({ id: 'export.pdf.cliplok' }),
                text: intl.formatMessage({ id: 'export.pdf.headerText' })
              }}
              imageSrc={imageUrl}
              boxParameters={boxParams}
            />
          </PDFViewer>
        </DialogContent>
        <DialogActions sx={styles.pdfDialogFooter} />
      </Dialog>
    </Layout>
  )
}

export default FrontPage
