/* tslint:disable */
/* eslint-disable */
/**
 * parbox BFF
 * Backend for frontend API for parbox app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BoxParametersClipsOffsets } from './BoxParametersClipsOffsets';
import {
    BoxParametersClipsOffsetsFromJSON,
    BoxParametersClipsOffsetsFromJSONTyped,
    BoxParametersClipsOffsetsToJSON,
} from './BoxParametersClipsOffsets';
import type { ClipTypes } from './ClipTypes';
import {
    ClipTypesFromJSON,
    ClipTypesFromJSONTyped,
    ClipTypesToJSON,
} from './ClipTypes';

/**
 * 
 * @export
 * @interface BoxParametersClips
 */
export interface BoxParametersClips {
    /**
     * 
     * @type {ClipTypes}
     * @memberof BoxParametersClips
     */
    clipType: ClipTypes;
    /**
     * 
     * @type {BoxParametersClipsOffsets}
     * @memberof BoxParametersClips
     */
    offsets: BoxParametersClipsOffsets;
}

/**
 * Check if a given object implements the BoxParametersClips interface.
 */
export function instanceOfBoxParametersClips(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clipType" in value;
    isInstance = isInstance && "offsets" in value;

    return isInstance;
}

export function BoxParametersClipsFromJSON(json: any): BoxParametersClips {
    return BoxParametersClipsFromJSONTyped(json, false);
}

export function BoxParametersClipsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoxParametersClips {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clipType': ClipTypesFromJSON(json['clipType']),
        'offsets': BoxParametersClipsOffsetsFromJSON(json['offsets']),
    };
}

export function BoxParametersClipsToJSON(value?: BoxParametersClips | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clipType': ClipTypesToJSON(value.clipType),
        'offsets': BoxParametersClipsOffsetsToJSON(value.offsets),
    };
}

