
import { Box } from '@mui/material'
import { forwardRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { Color, Vector3 } from 'three'
import { Edges, OrthographicCamera, PerspectiveCamera, PresentationControls } from '@react-three/drei'

import { styles } from './BoxVisualizer.style'
import { getExternalDimensions } from '../../helpers/box-util'
import { BoxParameters } from '../../openapi/api'

export interface BoxVisualizerProps {
  boxParams: BoxParameters
}

/**
 * Renders a canvas using Threejs, which displays a 3D view of a box
 * constructed from the current parameters.
 * @param width Width of box
 * @param height Height of box
 * @param length Length of box
 * @returns React component
 */
export const BoxVisualizer = forwardRef(({ boxParams }: BoxVisualizerProps, ref: React.ForwardedRef<HTMLCanvasElement>) => {
  const {
    externalWidth: width,
    externalHeight: height,
    externalLength: length
  } = getExternalDimensions(boxParams)

  // TODO: Considder if this can be refactored in a way that does not kill understandability
  // (if any such understandability even exists currently)
  // We are going to need similar computations of clip locations for exporting purposes
  // so it might make sense to move some of this to a util file
  const allClipLocations = (): Array<[x: number, y: number, z: number]> => {
    const locations: Array<[x: number, y: number, z: number]> = []
    const axisSigns = [-1, 1]
    // Generate all lengthwise clip locations
    for (const dist of boxParams.clips.offsets.lengthWise) {
      for (const axis1Sign of axisSigns) {
        for (const axis2Sign of axisSigns) {
          const x = (width / 2) * axis1Sign
          const y = (height / 2) * axis2Sign
          const z1 = -(length / 2) + dist
          const z2 = (length / 2) - dist
          locations.push([x, y, z1])
          locations.push([x, y, z2])
        }
      }
    }
    // Generate all heightwise clip locations
    for (const dist of boxParams.clips.offsets.heightWise) {
      for (const axis1Sign of axisSigns) {
        for (const axis2Sign of axisSigns) {
          const x = (width / 2) * axis1Sign
          const y1 = -(height / 2) + dist
          const y2 = (height / 2) - dist
          const z = (length / 2) * axis2Sign
          locations.push([x, y1, z])
          locations.push([x, y2, z])
        }
      }
    }
    // Generate all widthwise clip locations
    for (const dist of boxParams.clips.offsets.widthWise) {
      for (const axis1Sign of axisSigns) {
        for (const axis2Sign of axisSigns) {
          const x1 = -(width / 2) + dist
          const x2 = (width / 2) - dist
          const y = (height / 2) * axis2Sign
          const z = (length / 2) * axis1Sign
          locations.push([x1, y, z])
          locations.push([x2, y, z])
        }
      }
    }
    return locations
  }

  // TODO: Proper types and a proper clip geometry
  const clipMeshes = (): any[] => {
    return allClipLocations().map(pos => {
      return (
        <mesh position={pos} key={pos.toString()}>
          <boxGeometry args={[20, 20, 20]} />
          <meshStandardMaterial color={new Color(50 / 255, 50 / 255, 50 / 255)} />
          <Edges />
        </mesh>
      )
    })
  }

  // TODO: Implement with parameters
  const legMeshes = (boxDimensions: Vector3): any[] => {
    const legSize = 40
    const bottomThickness = 8
    const color = new Color(110 / 255, 38 / 255, 14 / 255)
    const legYPosition = boxDimensions.y / -2 - legSize / 2
    const leg = (xPosition: number, key: string): any => {
      return (
        <group key={key} position={[xPosition, legYPosition, 0]}>
          <mesh position={[0, -legSize / 2 - bottomThickness / 2, 0]}>
            <boxGeometry args={[legSize, bottomThickness, length]} />
            <meshStandardMaterial color={color} />
            <Edges />
          </mesh>,
          <mesh position={[0, 0, -(boxDimensions.z / 2 - legSize / 2)]}>
            <boxGeometry args={[legSize, legSize, legSize]} />
            <meshStandardMaterial color={color} />
            <Edges />
          </mesh>,
          <mesh position={[0, 0, boxDimensions.z / 2 - legSize / 2]}>
            <boxGeometry args={[legSize, legSize, legSize]} />
            <meshStandardMaterial color={color} />
            <Edges />
          </mesh>
        </group>
      )
    }
    return ([
      leg(boxDimensions.x / 2 - legSize / 2, 'left'),
      leg(-(boxDimensions.x / 2 - legSize / 2), 'right')
    ])
  }

  // Non visible canvas which renders an isometric view for pdf generation
  const IsometricViewCanvas = (): JSX.Element => {
    const size = (length + width + height) / 2 // A quick and dirty heuristic for camera size
    return (
      <Canvas style={styles.isometricCanvas} ref={ref} gl={{ preserveDrawingBuffer: true }}>
        <group>
          <OrthographicCamera
            manual // Necessary or r3f will mess with it
            makeDefault
            position={[0, 0, size]}
            zoom={1}
            top={size}
            bottom={-size}
            left={-size}
            right={size}
            near={0.1}
            far={size * 10}
          />
          <ambientLight intensity={0.5} />
          <directionalLight color='white' position={[2, 2, 5]} />
          <group rotation={[Math.PI / 6, -Math.PI / 4, 0]}>
            <mesh>
              <boxGeometry args={[width, height, length]} />
              <meshStandardMaterial color={new Color(110 / 255, 38 / 255, 14 / 255)} />
              <Edges />
            </mesh>
            {clipMeshes()}
            {legMeshes(new Vector3(width, height, length))}
          </group>
        </group>
      </Canvas>
    )
  }

  return (
    <Box component='div' sx={styles.canvas}>
      <>
        {IsometricViewCanvas()}
        <Canvas>
          <group>
            <PerspectiveCamera
              makeDefault
              position={[0, 0, length + width + height] /* Perhaps use <Bounds> to center instead */}
              near={0.1}
              far={50000}
            />
            <ambientLight intensity={0.5} />
            <directionalLight color='white' position={[2, 2, 5]} />
            <PresentationControls
              global
              rotation={[Math.PI / 8, Math.PI / 4, 0]} // Initial rotation
              polar={[-Math.PI / 2, Math.PI / 2]} // Vertical limits
              azimuth={[-Infinity, Infinity]} // Horizontal limits
            >
              <mesh>
                <boxGeometry args={[width, height, length]} />
                <meshStandardMaterial color={new Color(110 / 255, 38 / 255, 14 / 255)} />
                <Edges />
              </mesh>
              {clipMeshes()}
              {legMeshes(new Vector3(width, height, length))}
            </PresentationControls>
          </group>
        </Canvas>
      </>
    </Box>
  )
})

export default BoxVisualizer
