import { Box as MuiBox, BoxProps, Theme } from '@mui/material'
import { SystemProps } from '@mui/system'

export interface CustomBoxProps extends SystemProps<Theme> {
  sx?: BoxProps['sx']
  children?: React.ReactNode
  id?: string
}

/**
 * MUI box wrapper to fix an issue with three.js resulting in errors
 * @param props properties
 * @returns A MUI Box react component
 */
export const Box = (props: CustomBoxProps): JSX.Element => {
  return <MuiBox component='div' {...props} />
}
