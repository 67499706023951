/* tslint:disable */
/* eslint-disable */
/**
 * parbox BFF
 * Backend for frontend API for parbox app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductBox } from './ProductBox';
import {
    ProductBoxFromJSON,
    ProductBoxFromJSONTyped,
    ProductBoxToJSON,
} from './ProductBox';
import type { ProductStatus } from './ProductStatus';
import {
    ProductStatusFromJSON,
    ProductStatusFromJSONTyped,
    ProductStatusToJSON,
} from './ProductStatus';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    uuid: string;
    /**
     * 
     * @type {ProductBox}
     * @memberof Product
     */
    box: ProductBox;
    /**
     * 
     * @type {ProductStatus}
     * @memberof Product
     */
    status: ProductStatus;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    title: string;
}

/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "box" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'box': ProductBoxFromJSON(json['box']),
        'status': ProductStatusFromJSON(json['status']),
        'title': json['title'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'box': ProductBoxToJSON(value.box),
        'status': ProductStatusToJSON(value.status),
        'title': value.title,
    };
}

