import { Button, TextField, Typography } from '@mui/material'
import { Box } from '../custombox/CustomBox'
import { FC, KeyboardEvent, useState } from 'react'
import { authActions } from '../../features/auth/authSlice'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import Layout from '../layout/Layout'
import { styles } from './LoginPage.style'

export interface LoginPageProps {
  isLoading: boolean
  isError: boolean
  login: (username: string, password: string) => void
}

export const LoginPage: FC<LoginPageProps> = ({ login, isError }: LoginPageProps) => {
  const dispatch = useAppDispatch()

  const { username } = useAppSelector((state) => state.auth.user)
  const [password, setPassword] = useState<string>('')

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      login(username, password)
    }
  }
  return (
    <Layout>
      <Box sx={styles.content}>
        <Box sx={styles.loginTitleContainer}>
          <Typography variant='h2' sx={styles.text}>
            Login
          </Typography>
        </Box>

        <Box sx={styles.inputContainer}>
          <TextField
            value={username}
            sx={styles.input}
            InputProps={{ disableUnderline: true, size: 'medium' }}
            variant='filled'
            label='Username'
            required
            autoFocus
            onKeyPress={handleKeyPress}
            onChange={(e) => {
              dispatch(authActions.setUsername(e.target.value))
            }}
          />
          <TextField
            sx={styles.input}
            InputProps={{ disableUnderline: true, size: 'medium' }}
            value={password}
            variant='filled'
            label='Password'
            type='Password'
            required
            onKeyPress={handleKeyPress}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <Typography sx={isError ? { color: 'red' } : { visibility: 'hidden' }} variant='body2' component='div'>
            Invalid infomation
          </Typography>
        </Box>
        <Button
          sx={styles.loginButton}
          variant='contained'
          onClick={() => login(username, password)}
        >
          <Typography variant='h4'>
            Login
          </Typography>
        </Button>
      </Box>
    </Layout>
  )
}

export default LoginPage
