import { createStyles } from '../../theme'

export const styles = createStyles({
  pageHeader: {
    borderBottom: '1px solid black',
    padding: '8px',
    height: 100
  },
  headerTopContainer: {
    flexDirection: 'row',
    marginBottom: '8px'
  },
  headerLogoContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerText: {
    marginLeft: '20px',
    marginRight: '20px',
    fontSize: '12px'
  },
  pageContent: {
    height: 495 // Approximate <Landscape height> - 100 (from header)
  },
  infoBox: {
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
    padding: '8px',
    position: 'absolute',
    width: 250,
    height: 150,
    bottom: 0,
    right: 0,
    fontSize: '12px'
  },
  logo: {
    width: 42,
    height: 42
  },
  isometricImage: {
    objectFit: 'cover',
    width: '70%'
  }
})

export const box = (width: number, height: number): { style: any } => {
  return createStyles({
    style: {
      border: '1px solid black',
      width: width,
      height: height
    }
  })
}
