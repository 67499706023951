import { createStyles, theme } from '../../../theme'

export const styles = createStyles({
  accordionDetailsContainer: {
    paddingTop: 2
  },
  content: {
    padding: theme => theme.spacing(2)
  },
  inputBox: {
    width: '100%',
    marginBottom: 2
  },
  icon: {
    color: theme.palette.common.white
  },
  dimensionsBox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  sectionHeaderTitle: {
  },
  gridItem: {
    textAlign: 'center',
    width: '100%'
  },
  materialsGrid: {
    padding: 2,
    paddingTop: 0,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
