/* tslint:disable */
/* eslint-disable */
/**
 * parbox BFF
 * Backend for frontend API for parbox app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MaterialTypes = {
    Plywood: 'Plywood',
    Pine: 'Pine',
    PalletWood: 'Pallet Wood'
} as const;
export type MaterialTypes = typeof MaterialTypes[keyof typeof MaterialTypes];


export function MaterialTypesFromJSON(json: any): MaterialTypes {
    return MaterialTypesFromJSONTyped(json, false);
}

export function MaterialTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaterialTypes {
    return json as MaterialTypes;
}

export function MaterialTypesToJSON(value?: MaterialTypes | null): any {
    return value as any;
}

