/* tslint:disable */
/* eslint-disable */
/**
 * parbox BFF
 * Backend for frontend API for parbox app.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BoxParameters } from './BoxParameters';
import {
    BoxParametersFromJSON,
    BoxParametersFromJSONTyped,
    BoxParametersToJSON,
} from './BoxParameters';

/**
 * 
 * @export
 * @interface ProductBox
 */
export interface ProductBox {
    /**
     * 
     * @type {string}
     * @memberof ProductBox
     */
    uuid: string;
    /**
     * 
     * @type {BoxParameters}
     * @memberof ProductBox
     */
    boxParameters: BoxParameters;
}

/**
 * Check if a given object implements the ProductBox interface.
 */
export function instanceOfProductBox(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "boxParameters" in value;

    return isInstance;
}

export function ProductBoxFromJSON(json: any): ProductBox {
    return ProductBoxFromJSONTyped(json, false);
}

export function ProductBoxFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBox {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'boxParameters': BoxParametersFromJSON(json['boxParameters']),
    };
}

export function ProductBoxToJSON(value?: ProductBox | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'boxParameters': BoxParametersToJSON(value.boxParameters),
    };
}

