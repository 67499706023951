import Drawing from 'dxf-writer'
import { getPanelDimensions } from './box-util'
import { BoxParameters } from '../openapi/api'

/**
 * Function for generating a dxf string from given box parameters
 * @param boxParameters The box information
 * @returns A valid dxf string
 */
export function boxParamsToDxfString (boxParameters: BoxParameters): string {
  const dxfDrawing = new Drawing()
  dxfDrawing.setUnits('Millimeters')

  dxfDrawing.addLayer('l_panels', Drawing.ACI.WHITE, 'CONTINUOUS')
  dxfDrawing.setActiveLayer('l_panels')

  const panelDimensions = getPanelDimensions(boxParameters)

  const sideStartX = 0
  const sideStartY = 0

  const topStartX = 0
  const topStartY = panelDimensions.sidePanel.y + 200

  const endStartX = panelDimensions.sidePanel.x + 200
  const endStartY = topStartY

  dxfDrawing.drawPolyline([
    [sideStartX, sideStartY],
    [sideStartX + panelDimensions.sidePanel.x, sideStartY],
    [sideStartX + panelDimensions.sidePanel.x, sideStartY + panelDimensions.sidePanel.y],
    [sideStartX, sideStartY + panelDimensions.sidePanel.y]
  ], true)

  dxfDrawing.drawPolyline([
    [topStartX, topStartY],
    [topStartX + panelDimensions.topPanel.x, topStartY],
    [topStartX + panelDimensions.topPanel.x, topStartY + panelDimensions.topPanel.y],
    [topStartX, topStartY + panelDimensions.topPanel.y]
  ], true)

  dxfDrawing.drawPolyline([
    [endStartX, endStartY],
    [endStartX + panelDimensions.endPanel.x, endStartY],
    [endStartX + panelDimensions.endPanel.x, endStartY + panelDimensions.endPanel.y],
    [endStartX, endStartY + panelDimensions.endPanel.y]
  ], true)

  dxfDrawing.addLayer('l_text', Drawing.ACI.WHITE, 'CONTINUOUS')
  dxfDrawing.setActiveLayer('l_text')

  // TODO: Dynamic text size and locations dependent on box size

  dxfDrawing.drawText(
    sideStartX + 0.5 * panelDimensions.sidePanel.x - 50,
    sideStartY + 0.5 * panelDimensions.sidePanel.y - 25,
    50,
    0,
    'SIDE')

  dxfDrawing.drawText(
    topStartX + 0.5 * panelDimensions.topPanel.x - 50,
    topStartY + 0.5 * panelDimensions.topPanel.y - 25,
    50,
    0,
    'TOP')

  dxfDrawing.drawText(
    endStartX + 0.5 * panelDimensions.endPanel.x - 50,
    endStartY + 0.5 * panelDimensions.endPanel.y - 25,
    50,
    0,
    'END')

  return dxfDrawing.toDxfString()
}
