import { Box } from '../custombox/CustomBox'
import { FC, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { authActions } from '../../features/auth/authSlice'
import { paths } from '../../paths'
import { useAppDispatch, useAppSelector } from '../../state/hooks'
import FrontPage from '../frontpage/FrontPage'
import LoginPage from '../loginpage/LoginPage'
import ProductListPage from '../productlistpage/ProductListPage'

export const AuthAwareRouter: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { isAuthenticated, csrf } = useAppSelector((state) => state.auth)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const getCSRF = async (): Promise<void> => {
    fetch('/parbox/csrf/', {
      credentials: 'same-origin'
    })
      .then((res) => {
        const csrfToken = res.headers.get('X-CSRFToken')
        dispatch(authActions.setCSRF(csrfToken ?? ''))
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const getSession = async (): Promise<void> => {
    fetch('/parbox/session/', {
      credentials: 'same-origin'
    })
      .then(isResponseOk)
      .then((data) => {
        if (data.isAuthenticated === true) { // the linter complains that explicit comparrison is needed if it is just 'if (data.isAuthenticated)'
          dispatch(authActions.setIsAuthenticated(true))
          void whoami()
        } else {
          dispatch(authActions.setIsAuthenticated(false))
        }
        void getCSRF()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const whoami = async (): Promise<void> => {
    fetch('/parbox/whoami/', {
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    })
      .then(isResponseOk)
      .then((data) => {
        console.log('You are logged in as: ' + String(data.username))
        dispatch(authActions.setUser(data))
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const isResponseOk = async (response: Response): Promise<any> => {
    if (response.status >= 200 && response.status <= 299) {
      return await response.json()
    } else {
      const responseJson = await response.json()
      // eslint-disable-next-line
      throw Error(`${response.statusText} - ${responseJson.detail}`)
    }
  }

  const login = async (username: string, password: string): Promise<void> => {
    setIsLoading(true)
    fetch('/parbox/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrf
      },
      credentials: 'same-origin',
      body: JSON.stringify({ username: username, password: password })
    })
      .then(isResponseOk)
      .then((data) => {
        dispatch(authActions.setIsAuthenticated(true))
        setIsError(false)
        void getSession()
      })
      .catch((err) => {
        console.error(err)
        setIsError(true)
      }).finally(() => {
        setIsLoading(false)
      })
  }

  const logout = async (): Promise<void> => {
    fetch('/parbox/logout', {
      credentials: 'same-origin'
    })
      .then(isResponseOk)
      .then((data) => {
        dispatch(authActions.setIsAuthenticated(false))
        navigate(paths.login)
        void getCSRF()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    void getSession()
    // eslint-disable-next-line
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      if (location.pathname === paths.login) {
        navigate((location.state as any)?.from?.pathname ?? '')
      }
    }
  }, [isAuthenticated, location, navigate])

  return (
    <Box>
      {isAuthenticated && (
        <Routes>
          <Route path={paths.home} element={<ProductListPage logout={logout} />} />
          <Route path={`${paths.products}/:id`} element={<FrontPage logout={logout} />} />
          <Route path='*' element={<ProductListPage logout={logout} />} />
        </Routes>
      )}
      {!isAuthenticated && (
        <Routes>
          <Route path={paths.login} element={<LoginPage isLoading={isLoading} isError={isError} login={login} />} />
          <Route path='/*' element={<Navigate to={paths.login} state={{ from: location }} />} />
        </Routes>
      )}
    </Box>
  )
}

export default AuthAwareRouter
