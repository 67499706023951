import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserRole } from '../../helpers/user-util'

export interface User { // Do this through openapi when adding openapigenerator
  username: string
  firstName?: string
  lastName?: string
  email?: string
  role: UserRole
}

export interface AuthState {
  user: User
  csrf: string
  isAuthenticated: boolean
}

export const authInitialState: AuthState = {
  user: {
    username: '',
    role: UserRole.CUSTOMER
  },
  csrf: '',
  isAuthenticated: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setUser: (state: AuthState, action: PayloadAction<User| undefined>) => {
      state.user = action.payload !== undefined ? action.payload : authInitialState.user
    },
    setIsAuthenticated: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setCSRF: (state: AuthState, action: PayloadAction<string>) => {
      state.csrf = action.payload
    },
    setUsername: (state: AuthState, action: PayloadAction<string>) => {
      state.user.username = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const authActions = authSlice.actions
export default authSlice.reducer
