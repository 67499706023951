
import { BoxParameters } from '../openapi/api'

export const defaultBoxParameters: BoxParameters = {
  internalHeight: 400,
  internalLength: 800,
  internalWidth: 500,
  topMaterial: 'Plywood',
  bottomMaterial: 'Plywood',
  sidesMaterial: 'Plywood',
  topThickness: 10,
  bottomThickness: 10,
  sidesThickness: 10,
  clips: {
    clipType: 'small',
    offsets: {
      lengthWise: [],
      widthWise: [],
      heightWise: []
    }
  }
}

/**
 * Function for generating external dimensions based on the internal dimensions and thicknesses
 * @param boxParams Parameters of the box
 * @returns Object with external dimensions
 */
export function getExternalDimensions (boxParams: BoxParameters):
{ externalHeight: number, externalWidth: number, externalLength: number} {
  return {
    externalWidth: boxParams.internalWidth + 2 * boxParams.sidesThickness,
    externalLength: boxParams.internalLength + 2 * boxParams.sidesThickness,
    externalHeight: boxParams.internalHeight + boxParams.topThickness + boxParams.bottomThickness
  }
}

/**
 * Function for generation 2D panel dimensions based on given 3D box parameters
 * @param boxParams Parameters of the box
 * @returns Object with 2D panel dimensions
 */
export function getPanelDimensions (boxParams: BoxParameters): {
  sidePanel: {x: number, y: number}
  topPanel: {x: number, y: number}
  endPanel: {x: number, y: number}} {
  const sideX = boxParams.internalLength + (2 * boxParams.sidesThickness)
  const sideY = boxParams.internalHeight
  const topX = boxParams.internalLength + (2 * boxParams.sidesThickness)
  const topY = boxParams.internalWidth + (2 * boxParams.sidesThickness)
  const endX = boxParams.internalWidth
  const endY = boxParams.internalHeight

  return {
    sidePanel: { x: sideX, y: sideY },
    topPanel: { x: topX, y: topY },
    endPanel: { x: endX, y: endY }
  }
}
