import { Box } from '../../../custombox/CustomBox'
import { FC, ReactElement } from 'react'
import { TextField } from '@mui/material'
import { styles } from './ClipList.style'
import { FormattedMessage } from 'react-intl'

export interface ClipListProps {
  clips: number[]
  externalDist: number
  hasWriteRights: boolean
  onClipsChange: (clips: number[]) => void
}

/**
 * Displays a list of clip offsets, half of which are interactable (the other haf being symmetries)
 * @param clips The current clip offsets from the corner of the box
 * @param externalDist The external length, height or width of the box for the given axis
 * @param onClipsChange Callback function to update the state with new clip offsets
 * @returns a React component containing a list of clip offset textfields
 */
export const ClipList: FC<ClipListProps> =
({ clips, externalDist, hasWriteRights, onClipsChange }: ClipListProps) => {
  const updateClips = (index: number, newValue: number): void => {
    const newClips = [...clips]
    newClips[index] = newValue
    onClipsChange(newClips)
  }

  const listView = (): ReactElement[] => {
    // TODO: Change to use 'map' instead of 'reduce'?
    let listView: ReactElement[] = clips.reduce((array: ReactElement[], offset, index) => {
      array.push(
        <TextField
          sx={styles.listItem}
          key={index}
          type='number'
          value={offset}
          label={'Clip ' + (index + 1).toString()}
          disabled={!hasWriteRights}
          onChange={(e) => {
            updateClips(index, Number(e.target.value))
          }}
        />
      )
      return array
    }, [])

    listView = clips.reduceRight((array: ReactElement[], offset, index) => {
      const actualIndex = (2 * clips.length) - index
      const actualOffset = externalDist - offset
      array.push(
        <TextField
          sx={styles.listItem}
          key={actualIndex}
          type='number'
          value={actualOffset}
          disabled
          label={'Clip ' + actualIndex.toString()}
        />
      )
      return array
    }, listView)

    return listView
  }

  return (
    <Box sx={styles.listcontainer}>
      <div>
        <FormattedMessage id='boxConfiguration.cornerOffset' />
      </div>
      {listView()}
    </Box>
  )
}
