import { Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { Box } from '../../custombox/CustomBox'
import { FC } from 'react'
import { styles } from './MaterialsDesigner.style'
import { FormattedMessage, useIntl } from 'react-intl'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BoxParameters, MaterialTypes } from '../../../openapi/api'

export interface MaterialsDesignerProps {
  boxParams: BoxParameters
  hasWriteRights: boolean
  setBoxParams: React.Dispatch<React.SetStateAction<BoxParameters>>
}

/**
 * MaterialsDesigner allows setting the thickness and material type for the box panels
 * @param boxParams The parameters for the box being created
 * @param setBoxParams React 'useState' setter to modify the state of the box being created
 * @returns React component
 */
export const MaterialsDesigner: FC<MaterialsDesignerProps> =
({ boxParams, hasWriteRights, setBoxParams }: MaterialsDesignerProps) => {
  const materials: MaterialTypes[] = Object.values(MaterialTypes)
  const materialMenuItems = (): JSX.Element[] => materials.map(mat => {
    return <MenuItem key={mat} value={mat}>{mat}</MenuItem>
  })

  const intl = useIntl()

  return (
    <Accordion sx={styles.inputBox}>
      <AccordionSummary sx={styles.materialsBox} expandIcon={<ExpandMoreIcon sx={styles.icon} />}>
        <Typography sx={styles.sectionHeaderTitle}>
          <FormattedMessage id='boxConfiguration.materials' />
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetailsContainer}>
        <Grid container rowSpacing={2} columnSpacing={8} sx={styles.materialsGrid}>
          <Grid item xs={4}>
            <Box sx={styles.gridItem}>
              <FormattedMessage id='boxConfiguration.sides' />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={styles.gridItem}>
              <FormattedMessage id='boxConfiguration.top' />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={styles.gridItem}>
              <FormattedMessage id='boxConfiguration.bottom' />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={styles.gridItem}
              value={boxParams.sidesMaterial}
              label={intl.formatMessage({ id: 'boxConfiguration.material' })}
              disabled={!hasWriteRights}
              select
              onChange={e =>
                setBoxParams(oldParams =>
                  ({ ...oldParams, sidesMaterial: e.target.value as MaterialTypes })
                )}
            >
              {materialMenuItems()}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={styles.gridItem}
              value={boxParams.topMaterial.toString()}
              label={intl.formatMessage({ id: 'boxConfiguration.material' })}
              disabled={!hasWriteRights}
              select
              onChange={e =>
                setBoxParams(oldParams =>
                  ({ ...oldParams, topMaterial: e.target.value as MaterialTypes })
                )}
            >
              {materialMenuItems()}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={styles.gridItem}
              value={boxParams.bottomMaterial}
              label={intl.formatMessage({ id: 'boxConfiguration.material' })}
              disabled={!hasWriteRights}
              select
              onChange={e =>
                setBoxParams(oldParams =>
                  ({ ...oldParams, bottomMaterial: e.target.value as MaterialTypes })
                )}
            >
              {materialMenuItems()}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={styles.gridItem}
              value={boxParams.sidesThickness.toString()}
              label={`${intl.formatMessage({ id: 'boxConfiguration.thickness' })} ${intl.formatMessage({ id: 'boxConfiguration.mm' })}`}
              type='number'
              disabled={!hasWriteRights}
              onChange={e => {
                // MUI TextField with prop label='number' returns an empty string if letters are typed
                if (e.target.value === '') {
                  return
                }
                const inputNumber = Math.max(Number(e.target.value), 0)
                setBoxParams(oldParams =>
                  ({ ...oldParams, sidesThickness: inputNumber })
                )
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={styles.gridItem}
              value={boxParams.topThickness.toString()}
              label={`${intl.formatMessage({ id: 'boxConfiguration.thickness' })} ${intl.formatMessage({ id: 'boxConfiguration.mm' })}`}
              type='number'
              disabled={!hasWriteRights}
              onChange={e => {
                // MUI TextField with prop label='number' returns an empty string if letters are typed
                if (e.target.value === '') {
                  return
                }
                const inputNumber = Math.max(Number(e.target.value), 0)
                setBoxParams(oldParams =>
                  ({ ...oldParams, topThickness: inputNumber })
                )
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={styles.gridItem}
              value={boxParams.bottomThickness.toString()}
              label={`${intl.formatMessage({ id: 'boxConfiguration.thickness' })} ${intl.formatMessage({ id: 'boxConfiguration.mm' })}`}
              type='number'
              disabled={!hasWriteRights}
              onChange={e => {
                // MUI TextField with prop label='number' returns an empty string if letters are typed
                if (e.target.value === '') {
                  return
                }
                const inputNumber = Math.max(Number(e.target.value), 0)
                setBoxParams(oldParams =>
                  ({ ...oldParams, bottomThickness: inputNumber })
                )
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default MaterialsDesigner
